import "./WorkContainer.css"
import React,{useState} from "react";
import { Autoplay,Pagination  } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import ImgModal from "../ImgModal/ImgModal";

const imgData = [
  "/images/latestWork/1.webp",
  "/images/latestWork/2.webp",
  "/images/latestWork/3.webp",
  "/images/latestWork/4.webp",
  "/images/latestWork/5.webp",
  "/images/latestWork/6.webp",
  "/images/latestWork/7.webp",
  "/images/latestWork/8.webp",
  "/images/latestWork/9.webp",
  "/images/latestWork/10.webp",
  "/images/latestWork/11.webp",
  "/images/latestWork/12.webp",
  "/images/latestWork/13.mp4",
  "/images/latestWork/14.mp4",
  "/images/latestWork/15.mp4",
  "/images/latestWork/16.mp4",
  "/images/latestWork/17.mp4",
  

] 

function WorkContainer() {
  const [modal, setModal] = useState(false);
  const [imgSrc , setImgSrc] = useState("")

  const toggleModal = (e) => {
    setImgSrc(e.target.id)
    setModal((prev)=>{
        return !prev
    });
};

const handlePrevButtonClickInd = () => {
  const swiperInstance = document.querySelector('#work-cards-slider').swiper;
  swiperInstance.slidePrev();
};

const handleNextButtonClickInd = () => {
  const swiperInstance = document.querySelector('#work-cards-slider').swiper;
  swiperInstance.slideNext();
};
  return (
    <div style={{position:"relative"}}>
    <div className="WorkContainer-container standard-padding-space">
      {modal ? <ImgModal status={modal} showImg={imgSrc} allImages={imgData} closeModal={toggleModal} /> : <></>}
      <div className="w-100 container px-0 my-3">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <h3 className="body-heading" >OUR LATEST WORK</h3>
        <p className="body-paragraph shrinked-para">Discover the latest creations from Lee David Diamonds. Each piece in this collection highlights our commitment to artistry and craftsmanship. Let these breathtaking examples inspire you as you imagine your own unique design coming to life with <span style={{textWrap:"nowrap"}}>Lee David Diamonds.</span>        </p>


        <div className="container-fluid ">
          <div className="row card-list" style={{position:"relative"}}>
            <div className="col-12 ">
            <Swiper
              spaceBetween={20}
              pagination={true}
              breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                1000: {
                  slidesPerView: 3,
                },
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              loop={true}
              autoplay={
                {
                  delay:3000,
                  disableOnInteraction:false
                }
              }
              modules={[Autoplay,Pagination]}
              observer ={true}
              observeParents={true}
              parallax={true}
              className="testimonials-list py-5"
              style={{ position: "relative",paddingBottom:"100px" }}
              id="work-cards-slider"
                  >
                     <div
                  className="swiper-button-prev"
                  onClick={handlePrevButtonClickInd}
                >
                  <img
                    src="/images/icons/left-arrow-icon.svg"
                    className="arrow-img"
                    alt="arrow icon"
                  />
                </div>
                    {
                      imgData.map((imgSrc,ind)=>{
                        return(<>
                          <SwiperSlide key={ind} >
                            {
                              imgSrc.endsWith('.mp4')
                              ?
                              <div
                                className="sec"
                                id={imgSrc} onClick={toggleModal}
                                style={{backgroundImage:`url(${imgSrc.replace(/\.mp4$/, '.png')})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}
                              >
                              
                              </div>
                              :
                              <div
                                className="sec"
                                id={imgSrc} onClick={toggleModal}
                                style={{backgroundImage:`url(${imgSrc})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}
                              >
                                {/* <img src={imgSrc} className="img-fluid" id={imgSrc} onClick={toggleModal} /> */}

                              </div>

                            }
                          </SwiperSlide>
                          </>)
                      })
                    }
                      <div
                  className="swiper-button-next"
                  onClick={handleNextButtonClickInd}
                >
                  <img
                    src="/images/icons/right-arrow-icon.svg"
                    className="arrow-img"
                    alt="arrow icon"
                  />
                </div>
              </Swiper>
            </div>
          </div>
        </div>
        
       
      </div>
      </div>
  </div>
    </div>
    <div
      className="swiper-button-next-outer pe-4"
      onClick={handleNextButtonClickInd}
      >
      <img
        src="/images/icons/right-arrow-icon.svg"
        className="arrow-img"
        alt="arrow icon"
      />
    </div>
    <div
      className="swiper-button-prev-outer ps-4"
      onClick={handleNextButtonClickInd}
      >
      <img
        src="/images/icons/left-arrow-icon.svg"
        className="arrow-img"
        alt="arrow icon"
      />
    </div>
</div>

  )
}

export default WorkContainer