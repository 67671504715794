import React from 'react'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import SaleRegisterForm from '../component/SaleRegisterForm/SaleRegisterForm'
import PlainCenterText from '../component/PlainCenterText/PlainCenterText'
import OfferCardsContainer from '../component/OfferCardsContainer/OfferCardsContainer'

function WholesaleOffers() {
  return (
    <div>
        <Helmet>
            <title> 
            
            </title>
            <meta 
            name='description' 
            content="" 
            />

            </Helmet>
        
            <HeroBanner
              subHeading=""
              Heading="WHOLESALE OFFERS"
              nextLineSpanHeading=""
              // Paragraph="Occasionally, we have access to exclusive discounts and wholesale offers on loose diamonds. "
              bgImg="/images/creatives/wholesale-hero-bg.jpg"
              btn={false}
              sideLinks={false}
              marginBottom={false}
            />


            <OfferCardsContainer/>

            <SaleRegisterForm/>

            <div className='container pb-5 mb-4 mt-4'>
            <PlainCenterText
                bgImg='/images/creatives/wholesaleplain-text-bg.jpg'
                heading='PLEASE NOTE THAT THIS IS NOT AN ONLINE STORE.'
                paragraph='To purchase any items you see, call or text (905) 467-7766 or schedule an in-person appointment.'
                shrinkedHeading={"shrinked-heading-70"}
            />

            </div>

            <Footer
                newsletterSection={false}
            />
    </div>
  )
}

export default WholesaleOffers