import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import VideoContent from '../component/VideoContent/VideoContent'
import DiscoverServices from "../component/DiscoverServices/DiscoverServices"
import DropDownContent from '../component/DropDownContent/DropDownContent'
import Contactform from '../component/Contact Form/Contactform'
import { Helmet } from 'react-helmet'
import InteriorDesign from '../component/InteriorDesign/InteriorDesign'
import ProjectGlance from '../component/ProjectGlance/ProjectGlance'
import BlogCard from '../component/BlogCard/BlogCard'
import WorkContainer from '../component/WorkContainer/WorkContainer'
import PlainCenterText from '../component/PlainCenterText/PlainCenterText'
import BlogContainer from '../component/BlogContainer/BlogContainer'
import ServiceCheckList from '../component/ServiceCheckList/ServiceCheckList'
import GoogleRatingSection from '../component/GoogleRatingSection/GoogleRatingSection'


const textDropDown = [
    {
      title:"Coaching & Clinicians",
      content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    },
    {
      title:"Rider Development Program",
      content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    }
  ]


  const textBulletsData = [
    "Make an inquiry through phone, text, or our website form.", 
    "Share your needs and budget with us.", 
    "Schedule an in-person appointment to discuss design options and select your diamond.", 
    "Receive a quote and make a partial payment.", 
    "Your order goes into production and will be ready for pickup within 10-14 business days.", 
  ]

  const offerData = [
  {
    title:"Engagement Rings",
    img:'/images/creatives/offer-img-1.jpg'
  },
  {
    title:"Wedding Bands",
    img:'/images/creatives/offer-img-2.jpg'
  },
  // {
  //   title:"Eternity Bands",
  //   img:'/images/creatives/offer-img-3.jpg'
  // },
  {
    title:"Necklaces",
    img:'/images/creatives/offer-img-4.jpg'
  },
  {
    title:"Bracelets",
    img:'/images/creatives/offer-img-5.jpg'
  },
  {
    title:"Earrings",
    img:'/images/creatives/offer-img-6.jpg'
  },
  ]

  const servicesData  =[
  "Engagement Rings",
  "Wedding bands",
  "Eternity bands",
  "Necklaces",
  "Bracelets",
  "Earrings",
  ]

  const faqTextData = [
    {
      title:"What is the best way to contact Lee David Diamonds?",
      content:"The fastest way to reach us is by calling or texting (905) 467-7766. Alternatively, you can use our website form or email us at LeeDavidDiamonds@gmail.com."
    },
    {
      title:"How do I get a quote/price?",
      content:"A consultation is necessary to ensure we provide the most accurate and reliable quote."
    },
    {
      title:"Do you offer Lab-Grown Diamonds?",
      content:"We recognize that each client has unique preferences and values, which is why we offer both natural and lab-grown diamonds."
    },
    {
      title:"What materials are available at Lee David Diamonds?",
      content:"We offer a wide selection of natural and lab-grown diamonds, gemstones, gold, platinum, and more, all available for you to choose from."
    },
    {
      title:"Are your diamonds certified?",
      content:"Yes, all of our diamonds are certified by respected gemological laboratories, including the International Gemological Institute (IGI)."
    },
    {
      title:"Will my purchase come with an appraisal?",
      content:"Yes, with any purchase over $500, you’ll receive a luxury storage box and a third-party appraisal."
    },
    {
      title:"Do you do repair work?",
      content:"Repair services are available only for items purchased from Lee David Diamonds. For comprehensive repair needs, we recommend visiting your local jewelry store."
    },
    {
      title:"Do you have a social media presence?",
      content:"Yes, you can find us on Facebook and Instagram at @LeeDavidDiamonds."
    },
  ]
function Home() {
  return (
    <div >
      <Helmet>
          <title> 
          
          </title>
          <meta 
          name='description' 
          content="" 
          />
          
        </Helmet>
        
      
      <HeroBanner 
        subHeading=""
        Heading="CUSTOM LUXURY JEWELLERY"
        Paragraph="Welcome to Lee David Diamonds"
        NextLinePara="Where personalized luxury meets exceptional value"
        bgImg="/images/creatives/home-hero-bg.jpg"
        btn={false}
        sideLinks={false}
      />

      <TextContent
         subHeading="GET TO KNOW"
         heading= "LEE DAVID DIAMONDS"
         content={"We believe that buying jewellery should be a personalized and enjoyable experience. That’s why we provide private consultations, offering expert guidance in a relaxed, comfortable environment. Our goal is to understand your unique needs and preferences, helping you create a stunning piece of jewellery that perfectly reflects your style and budget.\nProudly serving clients in:"}
         customCodePara={"<p class=' body-paragraph my-1 mb-3 color-dark'>Milton, Oakville, Burlington, Halton Hills <br class='d-block d-lg-none' />and surrounding area.</p>"}
         postDesignContent="ABOUT US"
         btn={false}
         shiftRow={true}
         img="/images/creatives/about-content-img.png"
         reviewContent={false}
         btnImg={true}
         fontDark={true}
         subHeadingLine={false}
         signatureText='David'
         btnRef='/about'
         />

         <WorkContainer/>

      <TextContent
         subHeading=""
         heading= "How to Inquire and Make a Purchase"
         nextLineHeading=""
         content=""
         shiftRow={true}
         img="/images/creatives/home-text-2.png"
         reviewContent={false}
         btnImg={true}
         checkList={textBulletsData}
        
         btn={true}
         btnText={'BOOK A CONSULTATION'}
         btnRef='/contact'
         />
      

        <PlainCenterText
          bgImg="/images/creatives/home-plain-text-bg.jpg"
          subHeading="FAST DELIVERY"
          customCodeHeading="DREAM IDEAS, MADE TRUE IN UNDER <span style='text-wrap:nowrap;color:white;' class='body-heading mb-3'>TWO WEEKS!</span>"
          paragraph=""
        />

      <TextContent
         subHeading=""
         heading= "Let's Create Something Spectacular Together!"
         content={"Selecting the perfect diamond is a personal and important decision. That’s why I work by appointment only, providing dedicated attention to each client. Whether you're designing an engagement ring or upgrading a piece of jewelry, my customized approach ensures a smooth and stress-free experience."}
         customCodePara={"<p class=' body-paragraph my-1 mb-3 color-dark'>Book your appointment today and experience the benefits of working with <span style='text-wrap:nowrap;'>Lee David Diamonds.</span></p>"}
         nextLineHeading=""
         shiftRow={false}
         img="/images/creatives/home-text-3.png"
         reviewContent={false}
         btn={true}
         btnText={'BOOK A 30MIN CONSULTATION'}
         btnRef='/contact'
         btnImg={true}
         />

         {/* <CardCarousel
           subHeading=""
           heading="Areas of Expertise"
           content="Lee David Diamonds provides a diverse selection of high-quality, custom-designed jewelry tailored to your style, taste, and budget. Our specialties include:"
           dataAr={offerData}
         /> */}

         <ServiceCheckList
         subHeading=""
         heading="Areas of Expertise"
         content="Lee David Diamonds provides a diverse selection of high-quality, custom-designed jewelry tailored to your style, taste, and budget. Our specialties include:"
         listArr={servicesData}
         />

        <BlogContainer
          heading="Stay Updated on Our Latest News"
          content="Welcome to our blog section, where we explore trends in the jewellery industry, and provide custom design tips. Whether you're looking for expert advice or inspiration for your next piece, we're here to guide you every step of the way."
          btn={true}
          btnText="VIEW MORE"
          btnRef="/blog"
          
        />

        <div style={{background:"#F8F8F8"}} className='py-5'>
        <TextContent
         subHeading=""
         heading= "FREQUENTLY ASKED QUESTIONS"
         content={"Browse our FAQ section, where we address the most common questions about our diamonds, services, and custom design process.\n Whether you're new to shopping for jewellery or have questions about working with a private jeweller, we’re here to provide clear, helpful answers to guide your journey with us."}
         nextLineHeading=""
         btn={true}
         btnText={"LET'S CONNECT"}
         btnRef='/contact'
         shiftRow={true}
         faqData={faqTextData}
         reviewContent={false}
         btnImg={true}
         mobileOpposite={true}
         />    
         </div> 

         <GoogleRatingSection/>
       
      <Contactform
        heading='Connect with us'
      />


      <Footer
        newsletterSection={true}
      />
</div>
  )
}

export default Home